@keyframes pulse {
  0% {
    opacity: 0.125;
  }

  50% {
    opacity: 0.25;
  }

  100% {
    opacity: 0.125;
  }
}

@keyframes pinch {
  0% {
    transform: translate(-50%, -50%) scale(5);
    opacity: 0;
  }

  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}

@keyframes wiggly {
  0% {
    transform: rotate(45deg);
  }

  50% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(45deg);
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }

}

@keyframes shape__shift {
  0% {
    border-radius: 50%;
  }

  50% {
    border-radius: 5%;
  }

  100% {
    border-radius: 50%;
  }
}

@keyframes snap__rotate {
  0% {
    transform: scale(0.5) rotate(0deg);
  }

  25% {
    transform: scale(0.8) rotate(30deg);
  }

  100% {
    transform: scale(1) rotate(0deg);
  }
}

@keyframes bouncing {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0px);
  }
}
body.en {
  font-family: 'Montserrat', sans-serif;
}

body.bn {
  font-family: 'Noto Sans Bengali', sans-serif;
  line-height: 1.33 !important;
}

body.hi {
  font-family: 'Noto Sans Devanagari', sans-serif;
  line-height: 1.33 !important;
}

body.sa {
  font-family: 'Noto Sans Devanagari', sans-serif;
  line-height: 1.33 !important;
}

body.or {
  font-family: 'Noto Sans Oriya', sans-serif;
  line-height: 1.33 !important;
}

body.te {
  font-family: 'Noto Sans Telugu', sans-serif;
  line-height: 1.33 !important;
}

body.ta {
  font-family: 'Noto Sans Tamil', sans-serif;
  line-height: 1.33 !important;
}

body.kn {
  font-family: 'Noto Sans Kannada', sans-serif;
  line-height: 1.33 !important;
}
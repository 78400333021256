html,
body {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
  overflow-x: hidden;
  /* defining theme colors */
  --color-white: #FFFFFF;
  --color-bhasma: #707070;
  --color-kaala: #707071;
  --color-rakthalal: #FF4136;
  --color-matmaila: #C8C8C8;
  --color-slogan: #DDDDDD;

  /* difining scroll width */
  --scroll-width-open: 0;
  --scroll-width-close: 0;
}

#root {
  position: relative;
}

* {
  line-height: 1.125 !important;
}

*::selection {
  background: var(--color-bhasma);
  color: var(--color-white);
}

ul {
  list-style-type: none !important;
  padding: 0 !important;
  margin: 0 !important;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover,
button:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

html,
body,
#header {
  margin: 0 !important;
  padding: 0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Managing Scroll Bar */
body::-webkit-scrollbar {
  width: var(--scroll-width-open);
}

body::-webkit-scrollbar:horizontal {
  height: var(--scroll-width-open);
}

body::-webkit-scrollbar-track {
  background: var(--color-slogan);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--color-bhasma);
}

body.more-width::-webkit-scrollbar-thumb {
  background-color: var(--color-rakthalal);
}

/* Grabbing pointer */

.scrollable {
  cursor: all-scroll !important;
}

/* Importing Multilingual Fonts */
@import "./locales/fonts.css";

/* Importing Global Styles */
@import "./theme/global.css";

/* Importing Animations */
@import "./theme/animations.css";